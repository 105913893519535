var config = {
  cookieName: "AVSSESS",
  apiDomain: 'https://portalqaapi.avsresources.com',
  documentTitle: "AVS Resources | ",
  sessionTime: 1200000,
  company: "AVS",
  environment: "Production",
  themeColor: "#ec7833",
};

module.exports = config;
